import React, { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const DownloadCourseResultsReport = (props) => {
  const [showReport, setShowReport] = useState(props);
  const [fromDate, setFromDate] = useState("");
  const [reportLoading, setReportLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showNotification, setShowNotification] = useState(false);

  React.useEffect(() => {
    setShowReport(props.showReport);
  }, [props]);

  const hideReportModal = () => {
    props.toggleReportModal(false);
    setFromDate("");
    setErrorMessage("");
    setShowNotification(false);
  };

  const JSONToCSVConvertor = (JSONData, ShowLabel) => {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData =
      typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;

    var CSV = "";

    //This condition will generate the Label/Header
    if (ShowLabel) {
      var header = "";

      //This loop will extract the label from 1st index of on array
      for (var index in arrData[0]) {
        //Now convert each value to string and comma-seprated
        header += index + ",";
      }

      header = header.slice(0, -1);

      //append Label row with line break
      CSV += header + "\r\n";
    }

    //1st loop is to extract each row
    for (var i = 0; i < arrData.length; i++) {
      var row = "";

      //2nd loop will extract each column and convert it in string comma-seprated
      for (var col in arrData[i]) {
        row += '"' + arrData[i][col] + '",';
      }

      row.slice(0, row.length - 1);

      //add a line break after each row
      CSV += row + "\r\n";
    }

    if (CSV === "") {
      alert("Invalid data");
      return;
    }

    //Generate a file name
    var fileName = "CourseResultExport_";
    //this will remove the blank-spaces from the title and replace it with an underscore
    let dateStr = fromDate.toString();
    fileName += dateStr.substr(0, dateStr.indexOf(" 00"));

    //Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + escape(CSV);

    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;

    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadReport = async () => {
    setReportLoading(true);
    setShowNotification(false);

    try {
      console.log(props.reportApiUrl);
      const response = await fetch(
        props.reportApiUrl +
          "?date=" +
          new Intl.DateTimeFormat("en-US").format(fromDate),
        {
          method: "GET",
          headers: { Authorization: `Bearer ${props.token}` },
        }
      );

      if (!response.ok) setErrorMessage(response.statusText);
      else {
        const data = await response.json();
        setErrorMessage(null);

        let fields = Object.keys({
          id: "id",
          userId: "userId",
          completedAt: "completedAt",
          courseDocumentId: "courseDocumentId",
          createdAt: "createdAt",
          isPassed: "isPassed",
          score: "score",
          templateUrl: "templateUrl",
          templateVars: "templateVars",
          updatedAt: "updatedAt",
        });

        let result = [];
        data[0].forEach((item) => {
          result.push({
            userId: item.userId ?? "",
            courseId: item.courseDocumentId ?? "",
            certificateUserName: item.templateVars
              ? JSON.parse(item.templateVars)?.Name
              : "",
            certificateCulture: item.templateVars
              ? JSON.parse(item.templateVars)?.Culture
              : "",
            certificateCourse: item.templateVars
              ? JSON.parse(item.templateVars)?.Course.replace(",","_")
              : "",
            certificateAccreditation: item.templateVars
              ? JSON.parse(item.templateVars)?.Accreditation
              : "",
            certificateApproval: item.templateVars
              ? JSON.parse(item.templateVars)?.Approval
              : "",
            certificateDate: item.templateVars
              ? JSON.parse(item.templateVars)?.date
              : "",
            isPassed: item.isPassed ?? "",
            score: item.score ?? "",
            startDate: item.createdAt ?? "",
            completionDate: item.completedAt ?? "",
            certificateUrl: item.templateUrl ?? "",
          });
        });

        JSONToCSVConvertor(result, fields);
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(error);
    }

    setShowNotification(true);
    setReportLoading(false);
  };

  return (
    <div
      className={`fpds-Modal fpds-Modal--dialog ${showReport ? "is-open" : ""}`}
    >
      <div className="fpds-Modal-container">
        <div className="fpds-Modal-header">
          <div className="fpds-Modal-heading">
            Course Results Report Download
          </div>
          <button
            disabled={reportLoading}
            className="fpds-Modal-close fpds-Button fpds-Button--icon fpds-Button--subtle"
            onClick={hideReportModal}
          >
            <i className="fpds-Icon fpds-Icon--small">close</i>
          </button>
        </div>
        <div className="fpds-Modal-body">
          <div className="fpds-Stack u-widthFull">
            {showNotification && (
              <div
                className={`fpds-Alert fpds-Alert--${
                  errorMessage ? "warning" : "success"
                }`}
              >
                <i className="fpds-Alert-icon fpds-Icon fpds-Icon--medium">
                  {errorMessage ? "warning" : "success"}
                </i>
                <div className="fpds-Alert-content">
                  <div className="fpds-Alert-body">
                    {errorMessage
                      ? `Your report could not be downloaded, ${errorMessage}`
                      : "Your report has been downloaded successfully"}
                  </div>
                </div>
                <button
                  className="fpds-Alert-close fpds-Button fpds-Button--small fpds-Button--icon fpds-Button--subtle"
                  onClick={() => setShowNotification(false)}
                >
                  <i className="fpds-Icon fpds-Icon--small">close</i>
                </button>
              </div>
            )}

            <div className="fpds-Grid">
              <div className="fpds-Form-item fpds-Column">
                <label
                  htmlFor="fromDate"
                  className="fpds-Form-itemLabel fpds-FieldLabel"
                >
                  From Date
                </label>
                <div className="fpds-Form-itemControl">
                  <div className="fpds-TextField">
                    <DatePicker
                      selected={fromDate}
                      id="fromDate"
                      onChange={(date) => setFromDate(date)}
                      className="fpds-TextField-control"
                      dateFormat="dd/MM/yyyy"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="fpds-Modal-footer">
          <button
            className="fpds-Modal-actionCancel fpds-Button"
            onClick={hideReportModal}
          >
            Cancel
          </button>
          <button
            disabled={!props.token || !fromDate}
            className="fpds-Modal-actionPrimary fpds-Button fpds-Button--primary"
            onClick={downloadReport}
          >
            {props.token
              ? reportLoading
                ? "Downloading"
                : "Download"
              : "Authorizing"}
            {(reportLoading || !props.token) && (
              <div className="fpds-Button-iconEnd">
                <div className="fpds-Spinner fpds-Spinner--small"></div>
              </div>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DownloadCourseResultsReport;
