import React, { useState } from 'react';

import List from "./List";
import UploadCourse from './UploadCourse';
import DownloadCourseResultsReport from './DownloadCourseResultsReport';
import Loading from "../../components/Loading";

import { useAuth0 } from "../../react-auth0-spa";

const Courses = () => {
  const [show, setShow] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [auth0Token, setAuth0Token] = useState(false);
  const { getTokenSilently, loading } = useAuth0();
  const [apiUrl] = useState(process.env.REACT_APP_courseApiUrl);
  const [reportApiUrl] = useState(process.env.REACT_APP_courseResultsReportApiUrl);

  React.useEffect(() => {
    const populateAuth0Token = async () => {    
      setAuth0Token(await getTokenSilently());
    }
    populateAuth0Token();
  }, [getTokenSilently]);

  const toggleModal = (state) => setShow(state);
  const toggleReportModal = (state) => setShowReport(state);

    return (<div>{loading && <Loading show="true" />}
      {!loading && <div>
        <UploadCourse token={auth0Token} apiUrl={apiUrl} show={show} toggleModal={toggleModal} toggleReportModal={toggleReportModal}/>
        <DownloadCourseResultsReport token={auth0Token} reportApiUrl={reportApiUrl} showReport={showReport} toggleReportModal={toggleReportModal} />
        <List token={auth0Token} apiUrl={apiUrl} reportApiUrl={reportApiUrl} toggleModal={toggleModal} show={show} toggleReportModal={toggleReportModal}/>      
        </div>
      }
    </div>);
}

export default Courses;