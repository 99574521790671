import React from "react";
import { useAuth0 } from "../../react-auth0-spa";

const Navbar = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  return (
        <div
          id="header"
          className="u-paddingMedium u-backgroundWhite u-flex u-alignItemsCenter u-borderBottomMedium"
          style={{ "borderColor": "#336187" }}
        >
          <div id="header-brand" className="u-flexInitial">
            <img
              src="https://resources.fphcare.com/resources/corporate/media/system/logo.png"
              alt="Fisher & Paykel Healthcare"
              className="u-marginRightXLarge"
              style={{height: "26px"}}
            />
  
          </div>
          <div className="u-flexFill">
            
            <div className="fpds-Navbar-brand u-floatLeft">
    <a className="fpds-Navbar-brandLink fpds-Heading fpds-Heading--small" href="#/" style={{    overflow: "visible",
    "textOverflow": "unset"}}>Course Package Manager</a>
    </div>
          <form className="fpds-Navbar-toolbar u-marginLeftAuto u-floatRight">
        <div>
          {!isAuthenticated && (
            <button style={{height:"26px"}}
              className="fpds-Button fpds-Button--subtle"
              onClick={() => loginWithRedirect({})}
            >
              <i className="fpds-Icon fpds-Button-iconStart">user</i>
              <span>Log in</span>
            </button>
          )}

          {isAuthenticated && (
            <button style={{height:"26px"}}
              className="fpds-Button fpds-Button--subtle"
              onClick={() => logout()}
            >
              <i className="fpds-Icon fpds-Button-iconStart">user</i>
              <span>Log out</span>
            </button>
          )}
        </div>
      </form>
          </div>
        </div>
  );
};

export default Navbar;
