import React, { useState } from "react";
import { v4 as uuidv4 } from 'uuid';

const UploadCourse = (props) => {
    const [show, setShow] = useState(props);
    const [filePackage, setFilePackage] = useState(null);
    const [name, setName] = useState("");
    const [isPrivate, setIsPrivate] = useState(false);
    const [id, setId] = useState("");
    const [mainFile, setMainFile] = useState("");
    const [courseLoading, setCourseLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showNotification, setShowNotification] = useState(false);    

    React.useEffect(() => {
        refreshGuid();
        setShow(props.show);
    }, [props]);

    const refreshGuid = (e) => {
        if(e)
            e.preventDefault();
        setId(uuidv4());
    }

    const hideModal = () => {
        props.toggleModal(false);
    }

    const uploadFile = async () => {
        setCourseLoading(true);
        setShowNotification(false);

        try {         
            const formData = new FormData();
            formData.append("id", id);
            formData.append("package", filePackage);
            formData.append("name", name);
            formData.append("mainFile", mainFile);
            formData.append("isPrivate", isPrivate);

            const result = await fetch(props.apiUrl, {
                method: "POST",
                headers: { 
                    "Authorization" : `Bearer ${props.token}`
                },
                body: formData
            });
 
            if(!result.ok)
                setErrorMessage(result.statusText);
            else
                setErrorMessage(null);

        } catch (error) {
            console.error(error);
            setErrorMessage(error);
        }
            
        setShowNotification(true);
        setCourseLoading(false);
    };

    return (<div className={`fpds-Modal fpds-Modal--dialog fpds-Modal--xLarge ${show ? "is-open" : ""}`}>
        <div className="fpds-Modal-container">
            <div className="fpds-Modal-header">
                <div className="fpds-Modal-heading">Course Upload</div>
                <button disabled={courseLoading} className="fpds-Modal-close fpds-Button fpds-Button--icon fpds-Button--subtle" onClick={hideModal}>
                <i className="fpds-Icon fpds-Icon--small">close</i>
                </button>
            </div>
            <div className="fpds-Modal-body">
                <div className="fpds-Stack u-widthFull">
                    {showNotification &&
                        <div className={`fpds-Alert fpds-Alert--${errorMessage ? "warning" : "success"}`}>
                            <i className="fpds-Alert-icon fpds-Icon fpds-Icon--medium">{errorMessage ? "warning" : "success"}</i>
                            <div className="fpds-Alert-content">
                                <div className="fpds-Alert-body">
                                    {errorMessage ? `Your file could not be uploaded, ${errorMessage}` : "Your file has been uploaded successfully"}
                                </div>
                            </div>
                            <button className="fpds-Alert-close fpds-Button fpds-Button--small fpds-Button--icon fpds-Button--subtle" onClick={() => setShowNotification(false)}>
                                <i className="fpds-Icon fpds-Icon--small">close</i>
                            </button>
                        </div>
                    }

                    <form id="fileForm" className="fpds-Form" style={{width:"100%"}} encType="multipart/form-data">
                    <div className="fpds-Grid">
                        <div className="fpds-Form-item fpds-Column">
                        <label htmlFor="courseId" className="fpds-Form-itemLabel fpds-FieldLabel">Course Id</label>
                        <div className="fpds-Form-itemControl">
                            <div className="fpds-TextField has-iconEnd">
                            <input id="courseId" name="id" disabled={courseLoading} className="fpds-TextField-control" type="text" placeholder="Unique Identifier for the Course" value={id} onChange={e => setId(e.target.value)} />
                            <div className="fpds-TextField-iconEnd">
                                <button disabled={courseLoading} className="fpds-Button fpds-Button--small fpds-Button--icon fpds-Button--subtle" onClick={(e) => refreshGuid(e)}>
                                <i className="fpds-Icon fpds-Icon--small">refresh</i>
                                </button>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="fpds-Form-item fpds-Column">
                        <label htmlFor="courseName" className="fpds-Form-itemLabel fpds-FieldLabel">Course Name</label>
                        <div className="fpds-Form-itemControl">
                            <div className="fpds-TextField">
                            <input id="courseName" name="name" disabled={courseLoading} className="fpds-TextField-control" type="text" placeholder="e.g. F&P 950 System Clinician Training - Adult - Storyline" onChange={e => setName(e.target.value)} />
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="fpds-Grid">
                        <div className="fpds-Form-item fpds-Column">
                            <label htmlFor="coursePath" className="fpds-Form-itemLabel fpds-FieldLabel">Startup File</label>
                            <div className="fpds-Form-itemControl">
                                <div className="fpds-Select">
                                    <select id="startUpFileSelect" disabled={courseLoading} className="fpds-Select-control" onChange={(e) => setMainFile(e.target.selectedOptions[0].value)}>
                                        <option value="">--Please choose a startup file--</option>
                                        <option value="story.html">story.html (Storyline 360)</option>
                                        <option value="index.html">index.html (Rise 360)</option>
                                        <option value="presenter.html">presenter.html (Presenter Course)</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="fpds-Form-item fpds-Column">
                        <label htmlFor="isPrivate" className="fpds-Form-itemLabel fpds-FieldLabel">Is Private</label>
                        <div className="fpds-Form-itemControl">
                        <label className="fpds-Checkbox">
                            <input id="isPrivate" name="isPrivate" disabled={courseLoading} className="fpds-Checkbox-control" type="checkbox" onChange={e => setIsPrivate(e.target.checked)} />
                            <div className="fpds-Checkbox-icon"></div>
                            <div className="fpds-Checkbox-label"></div>
                        </label>
                        </div>
                        </div>
                    </div>
                    <div className="fpds-Form-item">
                        <label htmlFor="courseFile" className="fpds-Form-itemLabel fpds-FieldLabel">Select the ZIP file for upload</label>
                        <div className="fpds-Form-itemControl">
                        <input id="courseFile" name="file" disabled={courseLoading} className="fpds-Button" type="file" onChange={e => setFilePackage(e.target.files[0])} style={{padding: "10px",height: "3rem", width:"100%"}} />
                        </div>
                    </div>
                    </form>
                </div>
            </div>
            <div className="fpds-Modal-footer">
                <button className="fpds-Modal-actionCancel fpds-Button" disabled={courseLoading} onClick={hideModal}>Cancel</button>
                <button disabled={!props.token || !filePackage || !name || !id || !mainFile || courseLoading} className="fpds-Modal-actionPrimary fpds-Button fpds-Button--primary" onClick={uploadFile}>
                    {props.token ? courseLoading ? "Uploading" : "Upload" : "Authorizing"}
                    {(courseLoading || !props.token) && <div className="fpds-Button-iconEnd">
                        <div className="fpds-Spinner fpds-Spinner--small"></div>
                    </div>}            
                </button>
            </div>
        </div>
    </div>)
}
 
export default UploadCourse;

