import React from "react";
import { Router, Switch } from "react-router-dom";
import { useAuth0 } from "./react-auth0-spa";

// Components
import Navbar from "./components/Navbar";
import PrivateRoute from "./components/PrivateRoute";
import Loading from "./components/Loading";

//Utils
import history from "./utils/history";

// Routes
import Courses from "./pages/Courses";

const App = () => {
  const { loading } = useAuth0();

  return (
    <div className="App">
      <Router history={history}>
        <Navbar></Navbar>
        <main className="fpds-Container fpds-Container--fluid u-backgroundCanvas">
        
          <div className="fpds-Card" style={{"minHeight":"calc(100vh - 130px)"}}>
          <Loading show={loading} />
            <Switch>
              <PrivateRoute path="/courses" component={Courses} />
              <PrivateRoute path="/" component={Courses} />
            </Switch>
          </div>
        </main>
      </Router>
    </div>
  );
};

export default App;
