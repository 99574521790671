import React, { useState } from "react";
import Loading from "../../components/Loading";

const List = (props) => {
  const [showResult, setShowResult] = useState(false);
  const [privateCourses, setPrivateCourses] = useState(null);
  const [publicCourses, setPublicCourses] = useState(null);
  const [showPrivate, setShowPrivate] = useState(false);

  const showModal = () => props.toggleModal(true);
  const showReportModal = () => props.toggleReportModal(true);

  React.useEffect(() => {
    const callApi = async () => {
      if (props.token) {
        setShowResult(false);

        try {
          const response = await fetch(props.apiUrl, {
            method: "GET",
            headers: { Authorization: `Bearer ${props.token}` },
          });

          const responseData = await response.json();
          const courses = responseData
            .map(function (file) {
              return {
                id: file.id,
                name: file.name,
                url: file.url,
                isPrivate: file.isPrivate,
              };
            })
            .sort((a, b) => (a.name ? a.name.localeCompare(b.name) : a.name));

          setPrivateCourses(
            courses
              .filter((course) => {
                return course.isPrivate;
              })
              .map((course) => {
                return (
                  <tr key={course.id}>
                    <td>
                      {course.name ? course.name : <i>No name specified</i>}
                    </td>
                    <td>
                      <span>{course.url.replace(
                        "cpprod.blob.core.windows.net",
                        "resources.fphcare.com"
                      )}</span>
                    </td>
                    <td>
                      <button
                        className="fpds-Button fpds-Button--icon fpds-Button--subtlePrimary"
                        type="button"
                        title="Copy to Clipboard"
                        onClick={() => {navigator.clipboard.writeText(course.url.replace(
                          "cpprod.blob.core.windows.net",
                          "resources.fphcare.com"
                        ))}}
                      >
                        <i className="fpds-Icon">copy</i>
                      </button></td>
                  </tr>
                );
              })
          );

          setPublicCourses(
            courses
              .filter((course) => {
                return !course.isPrivate;
              })
              .map((course) => {
                return (
                  <tr key={course.id}>
                    <td>
                      {course.name ? course.name : <i>No name specified</i>}
                    </td>
                    <td>
                      <span>{course.url.replace(
                        "cpprod.blob.core.windows.net",
                        "resources.fphcare.com"
                      )}</span>
                    </td>
                    <td>

                    <button
                        className="fpds-Button fpds-Button--icon fpds-Button--subtlePrimary"
                        type="button"
                        title="Copy to Clipboard"
                        onClick={() => {navigator.clipboard.writeText(course.url.replace(
                          "cpprod.blob.core.windows.net",
                          "resources.fphcare.com"
                        ))}}
                      >
                        <i className="fpds-Icon">copy</i>
                      </button>
                    </td>
                  </tr>
                );
              })
          );

          setShowResult(true);
        } catch (error) {
          console.error(error);
        }
      }
    };

    callApi();
  }, [props]);

  return (
    <div>
      <Loading show={!showResult} />
      {showResult && (
        <div>
          <div className="fpds-Flow u-justifyBetween">
            <ul className="fpds-Tabs">
              <li className="fpds-Tabs-item">
                <a
                  className={`fpds-Tabs-itemOption ${
                    !showPrivate ? "is-selected" : ""
                  }`}
                  onClick={() => setShowPrivate(false)}
                  href="#/"
                >
                  <span className="fpds-Tabs-itemLabel">Public Courses</span>
                </a>
              </li>
              <li className="fpds-Tabs-item">
                <a
                  className={`fpds-Tabs-itemOption ${
                    showPrivate ? "is-selected" : ""
                  }`}
                  onClick={() => setShowPrivate(true)}
                  href="#/"
                >
                  <span className="fpds-Tabs-itemLabel">Private Courses</span>
                </a>
              </li>
            </ul>

            <div className="fpds-Flow fpds-Flow--alignEnd">
              <button
                className="fpds-Button fpds-Button--subtlePrimary u-marginRightMedium"
                onClick={showReportModal}
              >
                <i className="fpds-Icon is-iconStart">download</i>
                <span>Course Results Report</span>
              </button>

              <button
                className="fpds-Button fpds-Button--primary"
                onClick={showModal}
              >
                <i className="fpds-Icon is-iconStart">cloudUpload</i>
                <span>Upload New Package</span>
              </button>
            </div>
          </div>

          <table className="fpds-Table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Url</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{showPrivate ? privateCourses : publicCourses}</tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default List;
